<template>
  <studio-card id="pre-insctiption-studio">
    <template slot-scope="{}">
      <v-stepper class="background">
        <!-- TIPO CITA CITA -->
        <v-row justify="center">
          <v-col cols="11" md="10">
            <v-row>
              <v-col cols="12">
                <h1 class="text-h6 text-md-h3">{{ $t("precustomer.type") }}</h1>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer />
              <template v-for="(s, i) in services">
                <v-col cols="6" md="3" :key="`col-${i}`" class="py-0">
                  <v-card @click="select(s)">
                    <v-card-title class="mb-3">
                      {{
                        s.artist_type_id
                          ? $t("appointments.type." + s.name)
                          : s.name
                      }}
                    </v-card-title>

                    <v-avatar
                      color="primary"
                      size="100"
                      class="gris3--text text-h2 text-uppercase"
                    >
                      {{ s.name[0] }}
                    </v-avatar>

                    <v-card-subtitle></v-card-subtitle>
                  </v-card>
                </v-col>
                <v-spacer :key="`spacer-${i}`" />
              </template>
            </v-row>
          </v-col>
        </v-row>
        <br />
        <br />
        <br />
      </v-stepper>
    </template>
  </studio-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ClientIncription",
  components: {
    StudioCard: () => import("@/components/inscription/studio/Card"),
  },
  mounted() {
    if (!this.$route.params.back)
      this.$store.commit("inscriptions/RESET_CUSTOMER");

    this.fetchTattooer();
  },
  data() {
    return {
      type: null,
      services: [],
    };
    X;
  },
  computed: {
    ...mapState("inscriptions", ["tattooerState", "customerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
  },
  methods: {
    ...mapActions("services", ["getServices"]),
    fetchService() {
      this.getServices({ filters: { studio_id: this.tattooer.id } }).then(
        (response) => {
          this.services = response.data;
        }
      );
    },
    select(type) {
      this.type = type.name;

      let params = { token: this.$route.params.token, pre: true };

      if (this.customer.pre_tattooer === -1) {
        params.tattooer_id = this.customer.pre_tattooer;
      }

      params.artist = type.name;
      this.customer.type_id = type.id;

      this.$router.push({
        name: "customer_inscription_tattooer",
        params,
      });
    },
    ...mapActions("tattooers", ["getTattooerByToken"]),
    fetchTattooer() {
      this.getTattooerByToken(this.$route.params.token).then((response) => {
        this.tattooer = response;
        this.$set(this.customer, "tattooer", response);
        this.$store.commit("inscriptions/SET_CUSTOMER", this.customer);
        this.customer.reservation_type = this.$store.getters[
          "inscriptions/getTattooerLaborExange"
        ](response, "labor_exange");
        this.fetchService();
      });
    },
  },
};
</script>

<style lang="sass">
#pre-insctiption-studio
  .background
    min-height: calc( 100vh - 300px )
  .v-card
    text-align: center
    margin-top: 15px
    height: 86%
    .v-card__title
      display: inline-block
      white-space: nowrap
      width: 100%
    &:hover
      border: 1px solid var(--v-primary-base) !important
    .v-btn--fab.v-size--x-large
      height: 100px
      width: 100px
      .v-icon
        height: 50px
        font-size: 50px
        width: 50px
  &.final
    height: 100vh

    .v-responsive__sizer
      display: none !important
</style>